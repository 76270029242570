import React from "react"
import Form from "../../form"
import { createMarkup } from "../../../../utils/markup-creator"
import BackgroundImage from "../components/background-image"
import { SectionType } from "../../../types"

type SectionProps = {
  section: SectionType
  index: any
  [key: string]: any
}

const FormHero = ({ section, index, ...props }: SectionProps) => {

  return (
    <div className={`relative w-full ${section.advanced?.class || ``}`} style={!section.advanced?.class ? { height: `93vh` } : undefined}>
      <BackgroundImage
        url={section.advanced?.backgroundImageLg}
        mobileUrl={section.advanced?.backgroundImageSm}
        height={section.advanced?.backgroundHeight}
        backgroundPosition={section.advanced?.backgroundPosition}
        backgroundColor={section.advanced?.backgroundColor}
        backgroundFit={section.advanced?.backgroundFit}
      />
      <div className="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-0 py-10 flex justify-end" style={!section.advanced?.class ? { paddingTop: `calc(25vh)` } : undefined}>
        <div className="border-background border-solid border-4 rounded-lg bg-primary px-4 py-8 " style={{ maxWidth: `450px` }}>
          {section.blurb && <div dangerouslySetInnerHTML={createMarkup(section.blurb)} />}
          <Form form={section.form} />
        </div>
      </div>
    </div>
  )
}

export default FormHero
